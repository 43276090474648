import React from 'react';
import { Route, Link, Redirect } from 'react-router-dom';

import { Menu, Layout, PageHeader, Tag, Button, message } from 'antd';
import {
  FolderOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import LoadingComponent from '../../Common/Loading/Loading';
import StatusTagComponent from '../../Common/Status/Tag';
import PageComponent from './Page';

import './content.css';

import ContentApiConsumer from '../../../core/content/api';
import AuthStore from '../../../stores/Auth';
import { generateLog } from '../../../domain/Logs';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

export default class ContentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingNewVersion: false,
      tree: null,
    };
  }

  async componentDidMount() {
    await this.loadTree();
  }

  componentWillReceiveProps(next) {
    // console.log(next);
  }

  async loadTree() {
    const { scope } = AuthStore.getScope();

    const tree = await ContentApiConsumer.getContextsTree(scope);

    this.setState({
      loading: false,
      tree,
    });
  }

  async addVersionToContext(context) {
    this.setState({ loadingNewVersion: true });

    const { scope } = AuthStore.getScope();

    const newPage = await ContentApiConsumer.makePage({
      client_id: scope,
      context: {
        name: context.name,
        label: context.label,
      },
    });

    const savedPage = await ContentApiConsumer.savePage(newPage);

    if (savedPage) {
      this.loadTree();
      this.setState({ loadingNewVersion: false });
      this.navigateTo(savedPage._id, true);

      const user_email = JSON.parse(localStorage.getItem('current_user_meta')).email;
      const user_id = JSON.parse(localStorage.getItem('current_user_meta'))._id;
      const pageRegion = savedPage.context.name;
      const pageName = savedPage.props.label;

      const log = {
        app_location: 'Beon Studio',
        tenant_id: scope,
        time_stamp: new Date().toJSON(),
        user_email,
        user_id,
        resource: `${pageRegion}-${pageName}`,
        action_type: 'Página criada',
      };

      const logResult = await generateLog(log);

      if (logResult.status !== 201) {
        message.error('Falha ao gerar log');
      }
    }
  }

  async duplicatePage(page) {
    this.setState({ loadingNewVersion: true });

    const newPage = await ContentApiConsumer.duplicatePage(page);
    const savedPage = await ContentApiConsumer.savePage(newPage);

    if (savedPage) {
      this.loadTree();
      this.setState({ loadingNewVersion: false });
      this.navigateTo(savedPage._id, true);
    }
  }

  getPageUrl = pageId => {
    return `/${this.props.match.params.client_id}/content/${pageId}`;
  };

  navigateTo(pageId, isNew) {
    let target = this.getPageUrl(pageId);

    if (isNew) target += '/new';

    this.props.history.push(target);
  }

  getFirstPageUrl = () => {
    const first = this.state.tree.find(context => context.pagesSummary.length);

    const pagePath = first ? first.pagesSummary[0]._id : '';

    return this.getPageUrl(pagePath);
  };

  renderContextTree(context) {
    return (
      <SubMenu
        key={context.name}
        title={
          <span>
            <FolderOutlined />
            <span>{context.label}</span>
          </span>
        }
      >
        {context.pagesSummary.length ? (
          context.pagesSummary.map(page => (
            <Menu.Item key={`${context.name}:${page._id}`}>
              <Link to={this.getPageUrl(page._id)}>
                <StatusTagComponent
                  key="page:status"
                  status={page.props.status}
                />
                <span>{page.props.label}</span>
              </Link>
            </Menu.Item>
          ))
        ) : (
          <Menu.Item key={`${context.name}:empty`} disabled>
            <ExclamationCircleOutlined style={{ opacity: 2 }} />
            <span>Nenhuma página disponível</span>
          </Menu.Item>
        )}
        {context.props.isNewPageAllowed && (
          <Menu.Item key={`${context.name}:add`}>
            <Button
              type="ghost"
              size="small"
              icon={<PlusOutlined />}
              shape="round"
              loading={this.state.loadingNewVersion}
              onClick={() => this.addVersionToContext(context)}
            >
              Adicionar versão
            </Button>
          </Menu.Item>
        )}
      </SubMenu>
    );
  }

  render() {
    if (this.state.loading || this.state.tree === null)
      return <LoadingComponent />;

    return (
      <div className="content-wrapper">
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsible={false} theme="light" width={300}>
            <Menu
              style={{ width: 300, minHeight: '100%' }}
              defaultSelectedKeys={['homepage:padrao']}
              defaultOpenKeys={['homepage']}
              mode="inline"
            >
              <PageHeader
                backIcon={false}
                title="Construção de páginas"
                tags={<Tag color="blue">v 2.0</Tag>}
              />

              {this.state.tree.map(context => this.renderContextTree(context))}

            </Menu>
          </Sider>

          <Content style={{ padding: '0 50px' }}>
            <Route
              path="/:client_id/content/:id/:new?"
              render={({ match }) => (
                <PageComponent
                  id={match.params.id}
                  new={match.params.new}
                  client_id={match.params.client_id}
                  onDuplication={this.duplicatePage.bind(this)}
                />
              )}
            />
            <Route
              path="/:client_id/content"
              exact
              render={() => <Redirect to={this.getFirstPageUrl()} />}
            />
          </Content>
        </Layout>
      </div>
    );
  }
}
