import React, { useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';

import LayoutTemplateSelectComponent from './TemplateSelect';
import LayoutTemplateParamsComponent from './TemplateParams';

import AuthStore from '../../../../../../../../stores/Auth';
import TemplateApiConsumer from '../../../../../../../../core/templates/api';

const { Option } = Select;

const ElementLayoutTabComponent = props => {
  const { page } = props;

  const [element, setElement] = useState(props.element);
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    if (element?.template?._id) {
      loadTemplate(element.template._id);
    }
  }, []);

  const loadTemplate = templateId => {
    const { scope } = AuthStore.getScope();
    TemplateApiConsumer.getTemplate(scope, templateId).then(template => {
      setTemplate(template);
    });
  };

  const handleTemplateChange = value => {
    loadTemplate(value);
  };

  const getPageRegions = () => {
    const { regions } = page;
    return regions;
  };

  const handleRegionChange = region_id => {
    setElement(prevElement => ({
      ...prevElement,
      region_id: region_id,
    }));
  };

  const getTemplateId = () => {
    return element?.template?._id ?? null;
  };

  return (
    <>
      <Form.Item
        label="Nome do elemento"
        initialValue={element.props.label}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
        name="props.label"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Área de layout"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
        name="region_id"
        initialValue={element.region_id}
      >
        <Select onChange={handleRegionChange}>
          {getPageRegions().map(region => (
            <Option key={region._id} value={region._id}>
              {region.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <LayoutTemplateSelectComponent
        {...props}
        onChange={handleTemplateChange}
        elementType={element.type}
        templateId={getTemplateId()}
      />

      {template && (
        <LayoutTemplateParamsComponent
          {...props}
          element={element}
          template={template}
        />
      )}
    </>
  );
};

export default ElementLayoutTabComponent;
