import React from 'react';
import { Button } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const ElementActionVisibility = (props) => {
    const element = props.element;
    const handle = props.handle;

    const icon = element.props.isHidden ? (
        <EyeOutlined />
    ) : (
        <EyeInvisibleOutlined />
    );

    return (
        <Button type="default" shape="circle" icon={icon} onClick={handle} />
    );
};

export default ElementActionVisibility;
