import React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const ElementActionEdit = (props) => {
    const handle = props.handle;

    return (
        <Button
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            onClick={handle}
        />
    );
};

export default ElementActionEdit;
