import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ElementActionDelete = (props) => {
    const handle = props.handle;

    return (
        <Popconfirm
            title="Excluir um elemento é uma ação permanente. Se deseja apenas remover da página, considere oculta-lo antes de excluir."
            okText="Excluir"
            cancelText="Cancelar"
            onConfirm={handle}
        >
            <Button type="danger" shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
    );
};

export default ElementActionDelete;
