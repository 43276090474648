import React from 'react';
import { Row, Col, Tag } from 'antd';

import ElementTypeName from './Type/Name';
import SegmentationDescribe from '../Segmentation/Describe';

const ElementRowComponent = props => {
  const element = props.element;

  return (
    <Row
      className={[
        'page-element-row',
        element.props.isHidden ? 'hidden' : '',
      ].join(' ')}
      type="flex"
      align="middle"
    >
      <Col span={1}>#{element.props.order}</Col>
      <Col span={2}>
        <ElementTypeName type={element.type} />
      </Col>
      <Col span={6} className="title-col">
        <span>{element.props.label}</span>
        {element.props.isHidden && (
          <>
            {' '}
            <Tag color="magenta">Oculto</Tag>
          </>
        )}
      </Col>
      <Col span={9}>
        <SegmentationDescribe segmentation={element.segmentation} />
      </Col>
      <Col span={6} className="actions-col">
        {props.actions}
      </Col>
    </Row>
  );
};

export default ElementRowComponent;
