import React, { useState } from 'react';
import { Divider } from 'antd';

import './styles/supportButton.css';
import { CloseOutlined } from '@ant-design/icons';

const SupportButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={
        !isExpanded ? 'container container-close' : 'container container-expand'
      }
    >
      {isExpanded ? (
        <div className="content">
          <div className="content-header">
            <div className="beon-avatar-expanded" />
            <p className="expanded-title">Preciso de Ajuda</p>
            <div className="x-button" onClick={onButtonClick}>
              <CloseOutlined className="x-icon" />
            </div>
          </div>
          <div className="content-links">
            <div className="link-content">
              <div className="manual-icon" />
              <a
                className="link"
                target="_blank"
                href="https://sucesso.usebeon.io/manual-beon/"
              >
                Manual do Beonly
              </a>
            </div>
            <div className="link-content">
              <div className="support-icon" />
              <a
                className="link"
                target="_blank"
                href="https://sucesso.usebeon.io/preciso-de-ajuda/"
              >
                Solicitar ajuda no SUPORTE
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="support-button" onClick={onButtonClick}>
          <div className="beon-avatar" />
          <Divider type="vertical" style={{ background: 'rgb(0, 0, 0)' }} />
          <p className="beon-text">Suporte</p>
        </div>
      )}
    </div>
  );
};

export default SupportButton;
