import React, { useEffect } from 'react';
import { Layout, Menu, Row, Col } from 'antd';
import {
  PoweroffOutlined,
  UserOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import { useTenant } from '../../context/useTenant';
import { useUser } from '../../context/useUser';

import AuthStore from '../../stores/Auth';

import BannerAlert from '../Common/Alert';
import TenantIdComponent from '../Common/Tenant/Id';

const { Header } = Layout;

const MAINTENANCE = process.env.REACT_APP_MAINTENANCE;
const MAINTENANCE_TITLE = process.env.REACT_APP_MAINTENANCE_TITLE;
const MAINTENANCE_DESCRIPTION = process.env.REACT_APP_MAINTENANCE_DESCRIPTION;

const INCIDENT = process.env.REACT_APP_INCIDENT;
const INCIDENT_TITLE = process.env.REACT_APP_INCIDENT_TITLE;
const INCIDENT_DESCRIPTION = process.env.REACT_APP_INCIDENT_DESCRIPTION;

const SUCCESS = process.env.REACT_APP_SUCCESS_MESSAGE;
const SUCCESS_TITLE = process.env.REACT_APP_SUCCESS_TITLE;
const SUCCESS_DESCRIPTION = process.env.REACT_APP_SUCCESS_DESCRIPTION;

const HeaderComponent = () => {
  const { user, scope, meta } = AuthStore.getAuthenticated();
  const operacao = scope ? scope.scope : null;
  const { client_id } = useParams();
  const { handleTenantData, tenantData } = useTenant();
  const { operationsList } = useUser();

  useEffect(() => {
    if (operacao) {
      handleTenantData(operacao);
    }
  }, [operacao]);

  return (
    <>
      <Header className="app-header">
        <Row gutter={0}>
          <Col span={4}>
            <span className="app-header__logo">Beonly</span>
          </Col>

          <Col span={16}>
            <Menu
              key="ops"
              mode="horizontal"
              theme="dark"
              defaultSelectedKeys={['ops:content']}
              className="app-header__menu"
            >
              <Menu.Item key="ops:content">
                <Link to={`/${client_id}/content`}>
                  <span>Studio</span>
                </Link>
              </Menu.Item>

              {tenantData?.profile?.products?.includes('beon_search') && (
                <Menu.Item key="ops:search">
                  <Link to={`/${client_id}/search`}>
                    <span>Busca</span>
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item key="ops:catalog" className="app-header__menu-divider">
                <Link to={`/${client_id}/catalog`}>
                  <span>Catálogo</span>
                </Link>
              </Menu.Item>

              <Menu.SubMenu
                key="account"
                title={
                  <span>
                    <UserOutlined />
                    {meta ? meta.fullname : user}
                  </span>
                }
              >
                {operationsList && operationsList.length > 1 && (
                  <Menu.Item key="account:change-operation">
                    <Link to={`/login`}>
                      <SwapOutlined />
                      <span>Trocar operação</span>
                    </Link>
                  </Menu.Item>
                )}

                <Menu.Item key="account:logout">
                  <Link to={`/logout`}>
                    <PoweroffOutlined />
                    <span>Logout</span>
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu
                key="organization"
                title={
                  operacao ? (
                    <TenantIdComponent tenantData={tenantData} />
                  ) : null
                }
              />
            </Menu>
          </Col>
        </Row>
      </Header>

      {/true/i.test(INCIDENT) && (
        <BannerAlert
          message={INCIDENT_TITLE}
          description={INCIDENT_DESCRIPTION}
          type={'error'}
        />
      )}
      {/true/i.test(MAINTENANCE) && (
        <BannerAlert
          message={MAINTENANCE_TITLE}
          description={MAINTENANCE_DESCRIPTION}
          type={'warning'}
        />
      )}
      {/true/i.test(SUCCESS) && (
        <BannerAlert
          message={SUCCESS_TITLE}
          description={SUCCESS_DESCRIPTION}
          type={'success'}
        />
      )}
    </>
  );
};

export default HeaderComponent;
