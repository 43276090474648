export default [
  {
    name: 'datetime_timestamp',
    label: 'Data e hora',
    help: 'Segmente as interações por intervalos de data e hora.',
    input_type: 'datetime',
    allow_multiple_options: false,
    order: 0,
    operators: [
      'equals',
      'not_equals',
      'greater_than_equals_to',
      'less_than_equals_to',
    ],
    group: 'Data e hora',
    subgroup: 'Data',
  },
  {
    name: 'cart_items_references',
    label: 'Itens - Produtos no carrinho',
    help: 'Indica os produtos presentes no carrinho. Utilize como referência a SKU e demais referências ao produto.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 1,
    operators: ['is_in', 'not_in'],
    group: 'Situação do usuário',
    subgroup: 'Carrinho',
  },
  {
    name: 'cart_items_trees',
    label: 'Itens - Classificações no carrinho',
    help: 'Indica as classificações dos produtos presentes no carrinho.',
    allowed_options: 'catalog:trees:nodes',
    allow_multiple_options: true,
    order: 3,
    operators: ['is_in', 'not_in'],
    group: 'Situação do usuário',
    subgroup: 'Carrinho',
  },
  {
    name: 'cart_items_count',
    label: 'Itens - Contagem de SKUs únicas',
    help: 'Quantidade de itens diferentes no carrinho - não representa a soma das quantidades dos itens.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 2,
    operators: ['equals', 'greater_than_equals_to', 'less_than_equals_to'],
    group: 'Situação do usuário',
    subgroup: 'Carrinho',
  },
  {
    name: 'cart_items_sum_qty',
    label: 'Itens - Soma da quantidade de itens',
    help: 'Indica a soma da quantidade de todos os itens no carrinho.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 3,
    operators: ['equals', 'greater_than_equals_to', 'less_than_equals_to'],
    group: 'Situação do usuário',
    subgroup: 'Carrinho',
  },
  {
    name: 'cart_subtotal',
    label: 'Itens - Subtotal',
    help: 'Indica o subtotal do carrinho antes do desconto.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 4,
    operators: ['equals', 'greater_than_equals_to', 'less_than_equals_to'],
    group: 'Situação do usuário',
    subgroup: 'Carrinho',
  },
  {
    name: 'device_brand',
    label: 'Marca do dispositivo',
    help: 'Indica a marca do dispositivo sendo usado para o acesso, com maior acertividade para dispositivos móveis.',
    allowed_options: null,
    allow_multiple_options: true,
    order: 0,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex'],
    group: 'Rastreamento',
    subgroup: 'Dispositivo',
  },
  {
    name: 'device_model',
    label: 'Modelo do dispositivo',
    help: 'Indica o modelo do dispositivo sendo usado para o acesso, com maior acertividade para dispositivos móveis.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex'],
    group: 'Rastreamento',
    subgroup: 'Dispositivo',
  },
  {
    name: 'device_type',
    label: 'Tipo do dispositivo',
    help: 'Indica o tipo do dispositivo sendo utilizado para o acesso.',
    allowed_options: [
      ['mobile', 'Mobile'],
      ['desktop', 'Desktop'],
    ],
    allow_multiple_options: false,
    order: 0,
    operators: ['equals', 'not_equals', 'is_in', 'not_in'],
    group: 'Rastreamento',
    subgroup: 'Dispositivo',
  },
  {
    name: 'navigation_page_title',
    label: 'Título da página',
    help: 'Indica o título da página sendo visualizado pelo usuário.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['all'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_page_url',
    label: 'URL da página',
    help: 'Indica a URL da página sendo visualizada pelo usuário.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['all'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_page_type',
    label: 'Tipo da página',
    help: 'Indica o tipo da página sendo visualizada pelo usuário.',
    allowed_options: [
      ['homepage', 'Homepage'],
      ['catalog', 'Catálogo'],
      ['product', 'Produto'],
      ['cart', 'Carrinho'],
      ['checkout', 'Checkout'],
      ['success', 'Sucesso'],
      ['notfound', '404'],
      ['search', 'Busca'],
    ],
    allow_multiple_options: true,
    order: 0,
    operators: ['equals', 'not_equals', 'is_in', 'not_in'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_product_name',
    label: 'Produto - nome',
    help: 'Nome do produto sendo visto pelo usuário.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['is_in', 'not_in', 'regex'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_product_references',
    label: 'Produto - referências',
    help: 'Referência do produto sendo visualizado. Inclui SKUs, código de barras e outras referências.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['is_in', 'not_in'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_product_trees',
    label: 'Produto - classificações',
    help: 'Classificações nas quais o produto sendo visto pelo usuário está inserido.',
    allowed_options: 'catalog:trees:nodes',
    allow_multiple_options: true,
    order: 0,
    operators: ['is_in', 'not_in'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_product_price',
    label: 'Produto - preço',
    help: 'Preço final do produto sendo visualizado. Se item em promoção, represente o "preço por".',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['equals', 'greater_than_equals_to', 'less_than_equals_to'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_product_ispromo',
    label: 'Produto - em promoção',
    help: 'Estado promocional do produto sendo visualizado',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['truthy', 'falsy'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_product_availability',
    label: 'Produto - disponível para venda',
    help: 'Estado de disponibilidade do produto. Essa informação reflete a disponibilidade do item na última integração com a plataforma.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['truthy', 'falsy'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_catalog_trees',
    label: 'Catálogo - classificações',
    help: 'Classificações de catálogo sendo vistas pelo usuário.',
    allowed_options: 'catalog:trees:nodes',
    allow_multiple_options: true,
    order: 0,
    operators: ['equal', 'not_equal', 'is_in', 'not_in'],
    group: 'Histórico de navegação',
    subgroup: 'Página atual',
  },
  {
    name: 'navigation_visited_pages_urls',
    label: 'Páginas visitadas - URL',
    help: 'Indica a lista de URLs das páginas visitadas pelo usuário.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['all'],
    group: 'Histórico de navegação',
    subgroup: 'Histórico',
  },
  {
    name: 'navigation_visited_products',
    label: 'Produtos visitados - SKUs',
    help: 'Indica a lista de SKUs dos produtos visitados pelo usuário.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: [
      'equal',
      'not_equal',
      'is_in',
      'not_in',
      'greater_than_equals_to',
      'less_than_equals_to',
    ],
    group: 'Histórico de navegação',
    subgroup: 'Histórico',
  },
  {
    name: 'navigation_visited_trees',
    label: 'Classificações visitadas',
    help: 'Indica a lista de departamentos, categorias o coleções visitadas pelo usuário.',
    allowed_options: 'catalog:trees:nodes',
    allow_multiple_options: true,
    order: 0,
    operators: ['equal', 'not_equal', 'is_in', 'not_in'],
    group: 'Histórico de navegação',
    subgroup: 'Histórico',
  },
  {
    name: 'origin_referrer',
    label: 'Referência',
    help: 'Indica a referência do tráfego do usuário, que é o endereço do website que originou o tráfego - blogs ou afiliados, por exemplo.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 60,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex'],
    group: 'Rastreamento',
    subgroup: 'Origem do tráfego',
  },
  {
    name: 'origin_source',
    label: 'Canal',
    help: 'Indica o tipo de canal de origem do tráfego.',
    allowed_options: [
      ['search', 'Busca'],
      ['social', 'Social'],
      ['email', 'Email'],
      ['direct', 'Direto'],
      ['referral', 'Referência'],
    ],
    allow_multiple_options: true,
    order: 50,
    operators: ['equals', 'not_equals', 'is_in', 'not_in'],
    group: 'Rastreamento',
    subgroup: 'Origem do tráfego',
  },
  {
    name: 'origin_utm_source',
    label: 'UTM - Origem (utm_source)',
    help: 'Parâmetro utm_source. Com base na última campanha que trouxe o usuário pra loja.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex'],
    group: 'Rastreamento',
    subgroup: 'Origem do tráfego',
  },
  {
    name: 'origin_utm_medium',
    label: 'UTM - Mídia (utm_medium)',
    help: 'Parâmetro utm_medium. Com base na última campanha que trouxe o usuário pra loja.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 1,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex'],
    group: 'Rastreamento',
    subgroup: 'Origem do tráfego',
  },
  {
    name: 'origin_utm_campaign',
    label: 'UTM - Campanha (utm_campaign)',
    help: 'Parâmetro utm_campaign. Com base na última campanha que trouxe o usuário pra loja.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 2,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex'],
    group: 'Rastreamento',
    subgroup: 'Origem do tráfego',
  },
  {
    name: 'origin_utm_term',
    label: 'UTM - Palavra-chave (utm_term)',
    help: 'Parâmetro utm_term. Com base na última campanha que trouxe o usuário pra loja.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 3,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex'],
    group: 'Rastreamento',
    subgroup: 'Origem do tráfego',
  },
  {
    name: 'origin_utm_content',
    label: 'UTM - Variação (utm_content)',
    help: 'Parâmetro utm_content. Com base na última campanha que trouxe o usuário pra loja.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 4,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex'],
    group: 'Rastreamento',
    subgroup: 'Origem do tráfego',
  },
  {
    name: 'origin_type',
    label: 'Tipo de aquisição',
    help: 'Indica o tipo de aquisição da origem do usuário.',
    allowed_options: [
      ['paid', 'Paga'],
      ['organic', 'Orgânica'],
    ],
    allow_multiple_options: false,
    order: 40,
    operators: ['equals', 'not_equals', 'is_in', 'not_in'],
    group: 'Rastreamento',
    subgroup: 'Origem do tráfego',
  },
  {
    name: 'shipping_calculated',
    label: 'Frete - Frete calculado',
    help: 'Indica se o frete já foi calculado pelo usuário. O frete pode ter sido calculado no carrinho ou na página de um produto.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 5,
    operators: ['truthy', 'falsy'],
    group: 'Situação do usuário',
    subgroup: 'Carrinho',
  },
  {
    name: 'shipping_postcode',
    label: 'Frete - CEP do frete',
    help: 'Indica o CEP utilizado para calcular o frete.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 6,
    operators: ['equals', 'not_equals', 'is_in', 'not_in', 'regex', 'between'],
    group: 'Situação do usuário',
    subgroup: 'Carrinho',
  },
  {
    name: 'shipping_subtotal',
    label: 'Frete - Valor do frete',
    help: 'Indica o valor do frete selecionado pelo usuário.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 7,
    operators: ['equals', 'greater_than_equals_to', 'less_than_equals_to'],
    group: 'Situação do usuário',
    subgroup: 'Carrinho',
  },
  {
    name: 'visitor_logged_in',
    label: 'Visitante logado',
    help: 'Indica se o visitante atual é um usuário autenticado na loja.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['truthy', 'falsy'],
    group: 'Rastreamento',
    subgroup: 'Visitante',
  },
  {
    name: 'visitor_groups',
    label: 'Grupo do cliente',
    help: 'Ids de grupos informados para o cliente atual.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['is_in', 'not_in', 'empty', 'not_empty'],
    group: 'Rastreamento',
    subgroup: 'Visitante',
  },
  {
    name: 'visitor_new',
    label: 'Visitante novo',
    help: 'Indica se o usuário atual é novo, ou seja, não possui rastreamento anterior.',
    allowed_options: null,
    allow_multiple_options: false,
    order: 0,
    operators: ['truthy', 'falsy'],
    group: 'Rastreamento',
    subgroup: 'Visitante',
  },
  {
    name: 'visitor_returning',
    label: 'Visitante retornante',
    help: 'Indica se o usuário atual é retornante, ou seja, possui informações de rastreamento anteriores.',
    order: 0,
    operators: ['truthy', 'falsy'],
    group: 'Rastreamento',
    subgroup: 'Visitante',
  },
  {
    name: 'tags_trees',
    label: 'Categorias de interesse',
    help: 'Indica as categorias de interesse mapeados para o usuário.',
    allowed_options: 'catalog:trees:nodes',
    allow_multiple_options: true,
    order: 0,
    operators: ['is_in', 'not_in', 'empty', 'not_empty'],
    group: 'Interesses',
    subgroup: 'Catálogo',
  },
  {
    name: 'tags_trees_types',
    label: 'Tipos de categorias de interesse',
    help: 'Indica os tipos de categorias de interesse mapeados para o usuário.',
    allowed_options: 'catalog:trees:types',
    allow_multiple_options: true,
    order: 0,
    operators: ['is_in', 'not_in'],
    group: 'Interesses',
    subgroup: 'Catálogo',
  },
  // {
  //     name: 'geotag_region_name',
  //     label: 'Estado - nome',
  //     help: 'Nome por extenso do estado de origem. Exemplo: Santa Catarina.',
  //     order: 0,
  //     operators: ['equal', 'not_equal', 'is_in', 'not_in', 'regex'],
  //     group: 'Geolocalização',
  //     subgroup: 'Acesso',
  // },
  {
    name: 'geotag_region_code',
    label: 'Estado',
    help: 'Seleção do estado da federação.',
    allowed_options: [
      ['AC', 'Acre'],
      ['AL', 'Alagoas'],
      ['AP', 'Amapá'],
      ['AM', 'Amazonas'],
      ['BA', 'Bahia'],
      ['CE', 'Ceará'],
      ['DF', 'Distrito Federal'],
      ['ES', 'Espírito Santo'],
      ['GO', 'Goiás'],
      ['MA', 'Maranhão'],
      ['MT', 'Mato Grosso'],
      ['MS', 'Mato Grosso do Sul'],
      ['MG', 'Minas Gerais'],
      ['PA', 'Pará'],
      ['PB', 'Paraíba'],
      ['PR', 'Paraná'],
      ['PE', 'Pernambuco'],
      ['PI', 'Piauí'],
      ['RJ', 'Rio de Janeiro'],
      ['RN', 'Rio Grande do Norte'],
      ['RS', 'Rio Grande do Sul'],
      ['RO', 'Rondônia'],
      ['RR', 'Roraima'],
      ['SC', 'Santa Catarina'],
      ['SP', 'São Paulo'],
      ['SE', 'Sergipe'],
      ['TO', 'Tocantins'],
    ],
    allow_multiple_options: true,
    order: 1,
    operators: ['equals', 'not_equals', 'is_in', 'not_in'],
    group: 'Geolocalização',
    subgroup: 'Acesso',
  },
  // {
  //     name: 'geotag_country_name',
  //     label: 'País - nome',
  //     help: 'Nome por extenso do país de origem. Exemplo: Brasil.',
  //     order: 2,
  //     operators: ['equal', 'not_equal', 'is_in', 'not_in', 'regex'],
  //     group: 'Geolocalização',
  //     subgroup: 'Acesso',
  // },
  {
    name: 'geotag_country_code',
    label: 'País - código',
    help: 'Código do país de origem. Exemplo: BR.',
    order: 3,
    operators: ['equal', 'not_equal', 'is_in', 'not_in', 'regex'],
    group: 'Geolocalização',
    subgroup: 'Acesso',
  },
  {
    name: 'geotag_city',
    label: 'Cidade',
    help: 'Nome da cidade de origem. Exemplo: Florianópolis. Efetivo em grandes centros.',
    order: 4,
    operators: ['equal', 'not_equal', 'is_in', 'not_in', 'regex'],
    group: 'Geolocalização',
    subgroup: 'Acesso',
  },
  {
    name: 'geotag_postal',
    label: 'CEP - raíz postal',
    help: 'Raíz postal do CEP de origem. Exemplo: 88000.',
    order: 5,
    operators: ['equal', 'not_equal', 'is_in', 'not_in', 'regex', 'between'],
    group: 'Geolocalização',
    subgroup: 'Acesso',
  },
];
