import React from 'react';
import produce from 'immer';
import { Drawer, List, Button, Avatar } from 'antd';
import LoadingComponent from '../../../../Common/Loading/Loading';
import ElementTypeIcon from '../../../../Common/Element/Type/Icon';

import ContentApiConsumer from '../../../../../core/content/api';

import './styles/edit.css';

export default class PageElementAddComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            region: null,
            types: null,
        };

        this.listeners = [];

        this.store = props.store;
    }

    componentWillMount() {
        this.listeners.push(
            this.store.emitter.on(
                this.store.emitter.ELEMENT_ADD_START,
                (region) => {
                    this.setState({
                        visible: true,
                        region,
                    });
                }
            )
        );
    }

    componentDidMount() {
        this.loadTypes();
    }

    componentWillUnmount() {
        this.listeners.map((listener) => this.store.emitter.off(listener));
    }

    loadTypes() {
        ContentApiConsumer.getElementsTypes().then((types) => {
            this.setState({ types });
        });
    }

    closeDrawer = () => {
        this.setState(
            produce((draft) => {
                draft.visible = false;
                draft.element = {};
            })
        );
    };

    onDrawerClose = () => {
        this.closeDrawer();
    };

    getTypesGroups = () =>
        this.state.types
            .reduce((list, item) => {
                if (list.indexOf(item.group) < 0) list.push(item.group);
                return list;
            }, [])
            .sort((a, b) => a.localeCompare(b));

    getTypeActions(type) {
        const actions = [];

        if (type.is_available)
            actions.push([
                <Button
                    key={type.name}
                    type="primary"
                    onClick={() => this.handleSelectType(type)}
                >
                    <span>Selecionar</span>
                </Button>,
            ]);
        else
            actions.push([
                <Button key={type.name} type="default" disabled>
                    <span>Em breve</span>
                </Button>,
            ]);

        return actions;
    }

    handleSelectType(type) {
        this.addElement(type.name);
    }

    async addElement(type) {
        this.setState(
            produce((draft) => {
                draft.addElementLoading = true;
            })
        );

        const element = await ContentApiConsumer.makeElement({
            region_id: this.state.region._id,
            label: 'Novo elemento',
            type,
        });

        // init region edit
        this.store.emitter.dispatch(
            this.store.emitter.ELEMENT_EDIT_START,
            element
        );

        this.closeDrawer();
    }

    render() {
        if (!this.state.visible) return null;

        if (!this.state.types) return <LoadingComponent />;

        return (
            <Drawer
                title={`Adicionar elemento: ${this.state.region.label}`}
                placement="right"
                closable={true}
                onClose={this.onDrawerClose}
                visible={this.state.visible}
                width={900}
                className="page-element-add-wrapper"
            >
                {this.getTypesGroups().map((group) => (
                    <List
                        key={group}
                        header={group}
                        dataSource={this.state.types.filter(
                            (type) => type.group === group
                        )}
                        renderItem={(type) => (
                            <List.Item actions={this.getTypeActions(type)}>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            size="large"
                                            icon={
                                                <ElementTypeIcon
                                                    type={type.name}
                                                />
                                            }
                                        />
                                    }
                                    title={type.label}
                                    description={type.description}
                                />
                            </List.Item>
                        )}
                    />
                ))}
            </Drawer>
        );
    }
}
