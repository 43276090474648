import React from 'react';
import { Tag } from 'antd';

const ElementTypeName = (props) => {
    let label;

    switch (props.type) {
        case 'showcase':
            label = 'Vitrine';
            break;
        case 'snippet':
            label = 'HTML';
            break;
        case 'notification':
            label = 'Notificação';
            break;
        case 'subscribe':
            label = 'Subscrição';
            break;
        case 'banner':
            label = 'Banner';
            break;
        default:
            label = '';
            break;
    }

    return <Tag>{label}</Tag>;
};

export default ElementTypeName;
