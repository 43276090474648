import React from 'react';
import { Button, Popconfirm } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const ElementActionClone = (props) => {
    const handle = props.handle;

    return (
        <Popconfirm
            title="Continuar com a cópia deste elemento?"
            okText="Sim"
            cancelText="Não"
            onConfirm={handle}
        >
            <Button type="default" shape="circle" icon={<CopyOutlined />} />
        </Popconfirm>
    );
};

export default ElementActionClone;
