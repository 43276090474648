import React from 'react';
import { Form, Input, Button, Checkbox, Divider } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import SupportButton from './SupportButton';

const LoginFormComponent = ({ confirm }) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        confirm(values);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="login-form-component">
      <div className="login-beon-logo" />

      <Divider type="vertical" className="login-divider" />

      <Form
        name="login_form"
        form={form}
        initialValues={{ remember: true }}
        className="login-form"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Por favor digite seu usuário',
            },
          ]}
        >
          <Input
            className="login-form-input"
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Usuário"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Por favor digite sua senha',
            },
          ]}
        >
          <Input
            className="login-form-input"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Senha"
          />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Lembrar</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Entrar
          </Button>
        </Form.Item>
        <SupportButton />
      </Form>
    </div>
  );
};

export default LoginFormComponent;
