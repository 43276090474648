import React from 'react';
import { Button } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const ElementActionMove = (props) => {
    const handle = props.handle;
    const direction = props.direction;
    const icon =
        direction === 'up' ? <ArrowUpOutlined /> : <ArrowDownOutlined />;

    return (
        <Button type="default" shape="circle" icon={icon} onClick={handle} />
    );
};

export default ElementActionMove;
