import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, message, PageHeader } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { generateLog } from '../../../../domain/Logs';
import SearchApiConsumer from '../../../../core/search/api';
import { flattenObject, unflattenObject } from '../../../../utils/search';

import LoadingComponent from '../../../Common/Loading/Loading';
import Folders from './Folders/Folders';

const { Content } = Layout;

const SearchContent = ({ currentFolder, currentTab }) => {
  const { client_id } = useParams();
  const [formItems, setFormItems] = useState(null);
  const [tenantConfig, setTenantConfig] = useState(null);
  const [storedConfig, setStoredConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadForm();
  }, []);

  const findChangedValues = (body, stored = storedConfig) => {
    const changedValues = {};

    for (const key in body) {
      if (typeof body[key] === 'object' && typeof stored[key] === 'object') {
        const nestedChanges = findChangedValues(body[key], stored[key]);

        if (Object.keys(nestedChanges).length > 0) {
          changedValues[key] = nestedChanges;
        }
      } else if (body[key] !== stored[key]) {
        changedValues[key] = body[key];
      }
    }

    return changedValues;
  };

  const checkAndLogChanges = ({ action, body }) => {
    const user_email = JSON.parse(
      localStorage.getItem('current_user_meta'),
    )?.email;
    const user_id = JSON.parse(localStorage.getItem('current_user_meta'))?._id;

    const log = {
      app_location: 'Studio',
      tenant_id: client_id,
      time_stamp: new Date().toJSON(),
      user_email,
      user_id,
      resource: 'Busca',
      action_type: action,
      body,
    };

    if (action === 'Edição das configurações') {
      const changes = findChangedValues(body);
      if (!Object.entries(changes)?.length) return;
      log.body = changes;
    }

    generateLog(log);
  };

  const loadForm = async () => {
    try {
      setIsLoading(true);
      const tenantData = await SearchApiConsumer.getTenantConfig(client_id);
      const formData = await SearchApiConsumer.getSearchForm(client_id);

      const flattenedTenantData = flattenObject(tenantData);

      setStoredConfig(tenantData);
      setTenantConfig(flattenedTenantData);
      setFormItems(formData);
    } catch (e) {
      console.warn('Fetching tenant config/form failed with', e);
    } finally {
      setIsLoading(false);
    }
  };

  const saveTenantConfig = async values => {
    try {
      setIsLoading(true);

      const formValues = values;
      const formattedValues = formValues?.relevanceOrderItem?.reduce(
        (acc, { key, order }) => {
          acc[key] = order;
          return acc;
        },
        {},
      );
      delete formValues.relevanceOrderItem;

      const unflattenedFormValues = unflattenObject({
        tenantId: client_id,
        ...formValues,
        ...formattedValues,
      });

      const saved = await SearchApiConsumer.savePage(
        unflattenedFormValues,
        client_id,
      );

      if (saved.status === 200) {
        setFormItems(null);
        checkAndLogChanges({
          action: 'Edição das configurações',
          body: { ...unflattenedFormValues },
        });
        await loadForm();
        message.success('Informações salvas com sucesso!');
      } else {
        message.error(
          'Ocorreu um erro ao salvar as informações. Por favor, tente novamente',
        );
      }
    } catch (e) {
      console.log('saveTenantConfig failed with', e);
      message.error(
        'Ocorreu um erro ao salvar as informações. Por favor, tente novamente',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const cancelEditing = () => {
    setFormItems(null);
    loadForm();
  };

  if (!formItems || !tenantConfig || isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Content style={{ padding: '0 50px' }}>
      <PageHeader
        backIcon={false}
        title={
          <span>
            <span>{currentFolder}</span>
            <RightOutlined style={{ padding: '0 10px' }} />
            <span>{currentTab}</span>
          </span>
        }
      />

      <Folders
        currentTab={currentTab}
        formItems={formItems}
        tenantConfig={tenantConfig}
        handleSubmit={saveTenantConfig}
        onCancel={cancelEditing}
        logChanges={checkAndLogChanges}
      />
    </Content>
  );
};

export default SearchContent;
